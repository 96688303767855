import api from '../.'
import {
  type AnimalSearchPayload,
  type AnimalSearchResult,
  type ContactDetails,
  type Sex,
  type UserBasic,
  type UserFull,
  type UserType,
  type VeterinarianBasic,
} from '../../typings'
import { type V0HorseVetecardTemplateT } from '../pure/animal/horse/search'
import { type SearchAssociationProfileResponseSearchT } from '../pure/association/profile/search'
import { type SearchBreederProfileResponseSearchT } from '../pure/breeder/profile/search'
import { type V0GetIndividualSearchResponseT } from '../pure/individual/profile/search'
import { type SearchPetsitterProfileResponseT } from '../pure/petsitter/profile/search'
import { type SearchVeterinarianResult } from '../pure/search_veterinarian'

export const searchAnimalsDb = async (
  payload: AnimalSearchPayload
): Promise<AnimalSearchResult[]> => {
  const { id, name, motherName, fatherName, minBirthYear, maxBirthYear, breedCode, sexCode } =
    payload

  const convertSexCode = (sexCode?: Sex) => {
    switch (sexCode) {
      case 'male':
        return 'M'
      case 'female':
        return 'F'
      case 'gelding':
        return 'H'
      default:
        return null
    }
  }

  if (id) {
    const {
      data: { animal, identifier },
    } = (await api.get(`/0/animal/horse/${id}`)) as { data: V0HorseVetecardTemplateT }
    // @ts-ignore
    return [
      {
        name: animal.name,
        sex: animal.sex || undefined,
        breed: animal.species.breed || undefined,
        coatingColour: animal.coatingColour || undefined,
        birthDate: animal.birth?.date && new Date(animal.birth.date),
        identifier: {
          type: 'transponder',
          number: identifier?.number,
        },
      },
    ] as AnimalSearchResult[]
  } else {
    const params = {
      name,
      ...(motherName ? { motherName } : {}),
      ...(fatherName ? { fatherName } : {}),
      ...(minBirthYear ? { minBirthYear: parseInt(minBirthYear) } : {}),
      ...(maxBirthYear ? { maxBirthYear: parseInt(maxBirthYear) } : {}),
      ...(breedCode ? { breedCode } : {}),
      ...(sexCode ? { sexCode: convertSexCode(sexCode) } : {}),
    }
    const { data } = (await api.get(
      `/0/animal/horses/${encodeURIComponent(JSON.stringify(params))}`
    )) as { data: V0HorseVetecardTemplateT[] }
    return data.map(({ animal, identifier }) => ({
      name: animal.name || undefined,
      sex: animal.sex || undefined,
      breed: animal?.species?.breed || undefined,
      coatingColour: animal.coatingColour || undefined,
      birthDate: animal.birth?.date ? new Date(animal.birth.date) : undefined,
      identifier: {
        type: 'transponder',
        number: identifier?.number || undefined,
      },
    }))
  }
}

export const searchIndividuals = async (
  phrase: string
): Promise<
  Array<UserBasic & Pick<UserFull, 'email'> & { contact: Pick<ContactDetails, 'city'> }>
> => {
  const { data: individuals } = (await api.get(`/0/individual/search/${phrase}`)) as {
    data: V0GetIndividualSearchResponseT
  }
  const { data: breeders } = (await api.get(`/0/breeder/search/${phrase}`)) as {
    data: SearchBreederProfileResponseSearchT
  }
  const { data: associations } = (await api.get(`/0/association/search/${phrase}`)) as {
    data: SearchAssociationProfileResponseSearchT
  }
  const { data: petsitters } = (await api.get(`/0/petsitter/search/${phrase}`)) as {
    data: SearchPetsitterProfileResponseT
  }

  return [
    individuals.map(({ id, firstName, lastName, email, city }) => ({
      keycloakId: id,
      userType: 'individual' as UserType,
      firstName,
      lastName,
      email: email || '',
      contact: {
        city: city || '',
      },
    })),
    breeders.search.map(
      ({ id, profile: { email, firstName, lastName }, institution: { city } }) => ({
        keycloakId: id,
        userType: 'breeder' as UserType,
        firstName,
        lastName,
        email: email || '',
        contact: {
          city: city || '',
        },
      })
    ),
    associations.search.map(
      ({ id, profile: { email, firstName, lastName }, institution: { city } }) => ({
        keycloakId: id,
        userType: 'association' as UserType,
        firstName,
        lastName,
        email: email || '',
        contact: {
          city: city || '',
        },
      })
    ),
    petsitters.map(({ id, email, firstName, lastName, city }) => ({
      keycloakId: id,
      userType: 'petsitter' as UserType,
      firstName,
      lastName,
      email: email || '',
      contact: {
        city: city || '',
      },
    })),
  ].flat()
}

export const searchVeterinaries = async (phrase: string): Promise<VeterinarianBasic[]> => {
  const { data } = (await api.get(`/0/veterinarian/search/${phrase}`)) as {
    data: SearchVeterinarianResult[]
  }
  return data.map(({ id, institutionName, ...rest }) => ({
    veterinarianId: id,
    institutions: institutionName ? [{ name: institutionName }] : [],
    ...rest,
  }))
}
