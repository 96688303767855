import api from '..'
import { type Announcement, type AnnouncementPayload, type UserBasic } from '../../typings'
import { type InsertAdministratorAnnouncementResponseInsertionBodyT } from '../pure/administrator/announcement/insert'
import { type ListAdministratorAnnouncementsResponseListingBodyT } from '../pure/administrator/announcement/list'
import { type UpdateAdministratorAnnouncementResponseUpdateBodyT } from '../pure/administrator/announcement/update'
import { type ListAnnouncementsResponseListingBodyT } from '../pure/announcement/list'

/**
 * @todo do not treat view and management as a single collection
 */
export const getAnnouncements = async ({
  userType,
  keycloakId,
}: UserBasic): Promise<Announcement[]> => {
  switch (userType) {
    case 'administrator': {
      const { data: managedAnnouncements } = (await api.get(`/0/administrator/announcement`)) as {
        data: ListAdministratorAnnouncementsResponseListingBodyT
      }
      return managedAnnouncements.map(({ createdAt, now_published, ...announcement }) => ({
        ...announcement,
        createdAt: new Date(createdAt),
        isPublished: now_published,
      }))
    }

    default: {
      const { data: managedAnnouncements } = (await api.get(
        `/0/user/${keycloakId}/announcement`
      )) as { data: ListAnnouncementsResponseListingBodyT }
      return managedAnnouncements.map(({ createdAt, ...announcement }) => ({
        ...announcement,
        createdAt: new Date(createdAt),
        isPublished: true,
      }))
    }
  }
}

export const addAnnouncement = async (payload: AnnouncementPayload): Promise<Announcement> => {
  const { createdAt: createdAtString, isPublished, ...announcement } = payload
  const now_published = isPublished ?? false

  const createdAt = new Date(createdAtString)
  if (isNaN(createdAt.valueOf())) {
    throw new Error(
      `Cannot "addNews" as "createdAt" attribute needs to be a date. Provided: ${JSON.stringify(
        createdAtString
      )}`
    )
  }

  const {
    data: { id },
  } = (await api.post(`/0/administrator/announcement`, {
    ...announcement,
    createdAt,
    now_published,
  })) as { data: InsertAdministratorAnnouncementResponseInsertionBodyT }

  return {
    ...announcement,
    createdAt,
    isPublished: now_published,
    id,
  }
}

export const editAnnouncement = async (
  id: Announcement['id'],
  payload: AnnouncementPayload
): Promise<Announcement> => {
  const { createdAt: createdAtString, isPublished, ...announcement } = payload
  const now_published = isPublished ?? false
  const createdAt = new Date(createdAtString)

  const { data: updatedAnnouncement } = (await api.patch(`/0/administrator/announcement/${id}`, {
    createdAt,
    now_published,
    ...announcement,
  })) as { data: UpdateAdministratorAnnouncementResponseUpdateBodyT }

  return {
    ...updatedAnnouncement,
    isPublished: now_published,
    id,
  }
}

export const removeAnnouncement = async (id: Announcement['id']): Promise<Announcement['id']> => {
  await api.delete(`/0/administrator/announcement/${id}`)
  return id
}

export const dismissAnnouncement = async (
  id: Announcement['id'],
  { keycloakId }: UserBasic
): Promise<Announcement['id']> => {
  await api.put(`/0/user/${keycloakId}/announcement/${id}/read`)
  return id
}
