import api from '../.'
import {
  type Dictionary,
  type InboxDocument,
  type IntegrateFilePayload,
  type IntegrateVaccinationPayload,
  type Species,
  type UserBasic,
} from '../../typings'
import {
  ListOwnerInboxFileTemplateInvoice,
  ListOwnerInboxFileTemplateMandatoryVaccination,
  ListOwnerInboxFileTemplateMedical,
  ListOwnerInboxFileTemplateOtherVaccination,
  ListOwnerInboxFileTemplateTreatment,
  type ListOwnerInboxResponseListEntryT,
} from '../pure/owner/inbox/list'

export const getEmailFiles = async (
  { keycloakId, userType }: UserBasic,
  isArchived: boolean = false
): Promise<InboxDocument[]> => {
  const { data } = (await api.get(
    `/0/owner/${userType}/${keycloakId}/inbox/messages/${encodeURIComponent(
      JSON.stringify({
        file: { archived: isArchived },
      })
    )}`
  )) as { data: ListOwnerInboxResponseListEntryT[] }

  return data.flatMap(message =>
    message.files.map(({ id, name, type, url, template }) => {
      let initialValues: Dictionary
      if (ListOwnerInboxFileTemplateOtherVaccination.is(template)) {
        initialValues = {
          name,
          category: 'other',
          type: template.type === 'consent' ? 'other' : template.type || undefined, // TODO: This can be simplified after WCARD-641 is done.
          manufacturer: template.manufacturer ?? undefined,
          batchNumber: template.batchNumber ?? undefined,
          vaccinationDate: template.vaccinationDate ?? undefined,
          validFrom: template.validFrom ?? undefined,
          validTo: template.validTo ?? undefined,
          signedBy: template.signedBy
            ? {
                veterinarianId: 0,
                firstName: template.signedBy,
                lastName: '',
              }
            : undefined,
        }
      } else if (ListOwnerInboxFileTemplateMandatoryVaccination.is(template)) {
        initialValues = {
          name,
          category: 'mandatory',
          type: template.type === 'consent' ? 'other' : template.type || undefined, // TODO: This can be simplified after WCARD-641 is done.
          manufacturer: template.manufacturer ?? undefined,
          batchNumber: template.batchNumber ?? undefined,
          vaccinationDate: template.vaccinationDate ?? undefined,
          validFrom: template.validFrom ?? undefined,
          validTo: template.validTo ?? undefined,
          signedBy: template.signedBy
            ? {
                veterinarianId: 0,
                firstName: template.signedBy,
                lastName: '',
              }
            : undefined,
        }
      } else if (
        ListOwnerInboxFileTemplateTreatment.is(template) ||
        ListOwnerInboxFileTemplateMedical.is(template) ||
        ListOwnerInboxFileTemplateInvoice.is(template)
      ) {
        initialValues = {
          name,
          type: template.type === 'consent' ? 'other' : template.type ?? undefined, // TODO: This can be simplified after WCARD-641 is done.
          access: template.access ?? undefined,
          category: template.category,
          documentDate: template.documentDate ?? undefined,
          treatmentDate: template.treatmentDate ?? undefined,
        }
      } else {
        initialValues = template
      }
      return {
        id,
        date: message.received,
        name,
        type,
        url,
        initialValues,
        message: {
          id: message.id,
          email: message.sender.email,
          subject: message.subject || undefined,
          content: message.content.text || undefined,
        },
        vetecard: {
          id: message.vetecard.id,
          name: message.vetecard.animal.name,
          species: message.vetecard.animal.species as Species,
          birthDate: message.vetecard.animal.birth.date,
        },
      }
    })
  )
}

export const integrateEmailFile = async (
  { keycloakId, userType }: UserBasic,
  payload: IntegrateFilePayload
): Promise<IntegrateFilePayload> => {
  const {
    name,
    type,
    access,
    documentDate,
    treatmentDate = '',
    category,
    messageId,
    attachmentId,
  } = payload

  const file = {
    storageKey: name,
    type,
    access,
    documentDate: new Date(documentDate),
    treatmentDate: new Date(treatmentDate),
  }

  switch (category) {
    case 'medical':
      await api.post(
        `/0/owner/${userType}/${keycloakId}/inbox/message/${messageId}/attachment/${attachmentId}/integration/medical_file`,
        { medicalFile: file }
      )
      break

    case 'invoice':
      await api.post(
        `/0/owner/${userType}/${keycloakId}/inbox/message/${messageId}/attachment/${attachmentId}/integration/invoice`,
        { invoice: file }
      )
      break

    case 'treatment':
      await api.post(
        `/0/owner/${userType}/${keycloakId}/inbox/message/${messageId}/attachment/${attachmentId}/integration/treatment`,
        { treatment: file }
      )
      break

    default:
      throw new Error(`Unhandled file category ${JSON.stringify(category)}`)
  }

  return payload
}

export const integrateEmailVaccination = async (
  { keycloakId, userType }: UserBasic,
  payload: IntegrateVaccinationPayload
): Promise<IntegrateVaccinationPayload> => {
  const {
    name,
    type,
    manufacturer,
    batchNumber,
    vaccinationDate,
    validFrom,
    validTo,
    signedBy,
    category,
    messageId,
    attachmentId,
  } = payload

  const vaccination = {
    storageKey: name,
    type,
    manufacturer,
    batchNumber,
    vaccinationDate: new Date(vaccinationDate),
    validFrom: new Date(validFrom),
    validTo: new Date(validTo),
    signedBy,
  }

  switch (category) {
    case 'mandatory':
      await api.post(
        `/0/owner/${userType}/${keycloakId}/inbox/message/${messageId}/attachment/${attachmentId}/integration/mandatory_vaccination`,
        { mandatoryVaccination: vaccination }
      )
      break

    case 'other':
      await api.post(
        `/0/owner/${userType}/${keycloakId}/inbox/message/${messageId}/attachment/${attachmentId}/integration/other_vaccination`,
        { otherVaccination: vaccination }
      )
      break

    default:
      throw new Error(`Unhandled file category ${JSON.stringify(category)}`)
  }

  return payload
}

export const archiveEmailFile = async (
  { keycloakId, userType }: UserBasic,
  document: InboxDocument
): Promise<InboxDocument> => {
  await api.put(
    `/0/owner/${userType}/${keycloakId}/inbox/message/${document.id}/attachment/${document.id}/archival`,
    { archived: true }
  ) //FIXME: deliver message identifier here
  return document
}

export const deleteEmailFile = async (
  { keycloakId, userType }: UserBasic,
  document: InboxDocument
): Promise<InboxDocument> => {
  await api.delete(
    `/0/owner/${userType}/${keycloakId}/inbox/message/${document.id}/attachment/${document.id}`
  ) //FIXME: provide a message identifier
  return document
}
