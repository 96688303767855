// @ts-nocheck
import { flow, pipe } from 'fp-ts/function'
import { isLeft as isLeftEither, left as leftEither, right as rightEither } from 'fp-ts/Either'
import { isNone, none, some } from 'fp-ts/Option'
import { flatMap, fromEither, of, TaskEither } from 'fp-ts/TaskEither'

import * as t from 'io-ts'
import { failure } from 'io-ts/PathReporter'
import { nullable } from 'io-ts/Type'

import { Api } from '../../..'
import { V0VeterinarianProfile } from '.'
import { Institution, InstitutionProfile, Veterinarian } from '..'


export const V0FetchVeterinarianProfileRequest = t.type({
  id: V0VeterinarianProfile.props['id'],
})
export type V0FetchVeterinarianProfileRequestT = t.TypeOf<typeof V0FetchVeterinarianProfileRequest>

export const fetchVeterinarianProfile = (api: Api) => async (
  request: V0FetchVeterinarianProfileRequestT
): Promise<V0FetchVeterinarianProfileResponseT|null> => pipe(
  of(request),
  flatMap(
    veterinarian =>
      async () => api.get(`/${encodeURIComponent(0)}/veterinarian/${encodeURIComponent(veterinarian.id)}/profile`)
        .then(({ data, status }) => rightEither(status === 204 ? none : some(data)), leftEither)
  ),
  flatMap(
    flow(
      data => isNone(data) ? rightEither(null) : V0FetchVeterinarianProfileResponse.decode(data.value),
      fromEither
    )
  ),
  async (task: TaskEither<t.ValidationError[], V0FetchVeterinarianProfileResponseT>) => {
    const response = await task()
    if (isLeftEither(response))
    {
      console.error(failure(response.left).join('\n'))
      throw new Error(failure(response.left).join('\n'))
    }
    else
    {
      return response.right
    }
  }
)

export const V0FetchVeterinarianProfileResponse = t.intersection([
  Veterinarian,
  t.type({
    profile: nullable(V0VeterinarianProfile),
    institutions: t.array(
      t.intersection([
        Institution,
        t.type({ profile: nullable(InstitutionProfile) }),
      ])
    ),
  }),
])
export type V0FetchVeterinarianProfileResponseT = t.TypeOf<typeof V0FetchVeterinarianProfileResponse>
