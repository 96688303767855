import { dataUriToBuffer } from 'data-uri-to-buffer'
import { pipe } from 'fp-ts/lib/function'
import api from '../.'
import {
  type EditDescriptionPayload,
  type EditMarkingPayload,
  type EditPassportPayload,
  type FileInput,
  type UserBasic,
  type UserShared,
  type VetecardBasic,
  type VetecardFull,
  type VetecardsPayload,
  type VeterinarianBasic,
} from '../../typings'
import getCroppedImg from '../../utils/cropImage'
import { isOwnerType } from '../../utils/helpers'
import toTitleCase from '../../utils/toTitleCase'
import { type EmployedVeterinarian } from '../pure/employed_veterinarian'
import { type V0GetIndividualSearchResponseT } from '../pure/individual/profile/search'
import { type ListOwnerVetecardsResponseT } from '../pure/owner/vetecard/list'
import { type ListPetsitterVetecardsResponseListingBodyT } from '../pure/petsitter/vetecard/list'
import { type VetecardAnimalSexT, type VetecardWithDetailsT } from '../pure/vetecard/details'
import { type ListVetecardPetsittersResponseT } from '../pure/vetecard/petsitter/list'
import { type ListVetecardVeterinariansResponseT } from '../pure/vetecard/veterinarians/list'
import { type V0FetchVeterinarianProfileResponseT } from '../pure/veterinarian/profile/fetch'
import { type ListVeterinarianVetecardsResponseT } from '../pure/veterinarian/vetecard/list'
import { getMinioClient, getObject, getPresignedUrl, putObject } from './storage'
import { getVetecardRequests } from './vetecardRequests'

const photoBucketName = process.env.MINIO_BUCKET_VETECARD_PHOTO as string

export const getVetecardPhotoUrl = async (
  vetecardId: VetecardBasic['id']
): Promise<string | undefined> => {
  const storage = await getMinioClient()
  // Run getObject first because getPresignedUrl does not throw error if not present.
  await getObject(storage, vetecardId, photoBucketName)
  return getPresignedUrl(storage, vetecardId, photoBucketName)
}

export const getVetecards = async (
  user: UserBasic,
  params: VetecardsPayload = {},
  options: { withImage?: boolean; withRequests?: boolean } = {}
): Promise<{ vetecards: VetecardBasic[]; hasMore: boolean }> => {
  const { keycloakId, userType } = user
  let data

  switch (userType) {
    case 'veterinarian': {
      let limit: number | null
      let offset: number | null
      if (typeof params.limit !== 'undefined' && typeof params.page !== 'undefined') {
        limit = params.limit
        offset = params.limit * params.page
      } else {
        limit = offset = null
      }
      const responseProfile = await api.get(`/0/veterinarian/${keycloakId}/profile`)
      const profile = responseProfile.data as V0FetchVeterinarianProfileResponseT
      if (profile === null) {
        throw new Error(`Missing profile for veterinarian ${keycloakId}`)
      }
      const searchParams = new URLSearchParams({
        ...(limit === null ? {} : { limit: limit.toString() }),
        ...(offset === null ? {} : { offset: offset.toString() }),
      })
      const response = await api.get(
        `/0/veterinarian/${profile.id}/vetecard${
          searchParams.size === 0 ? '' : `?${searchParams.toString()}`
        }`
      )
      data = response.data as ListVeterinarianVetecardsResponseT
      break
    }

    case 'individual':
    case 'association':
    case 'breeder': {
      const response = await api.get(`/0/${userType}/${keycloakId}/vetecard`)
      data = response.data as ListOwnerVetecardsResponseT
      break
    }

    case 'petsitter': {
      const response = await api.get(`/0/petsitter/${keycloakId}/vetecards`)
      data = response.data as ListPetsitterVetecardsResponseListingBodyT
      break
    }

    default:
      throw new Error(`Failed to "getVetecards" for unknown user ${JSON.stringify(user)}`)
  }

  const vetecards = await Promise.all(
    data.map(async ({ id, animal: { name, species, birth } }) => ({
      id,
      name: toTitleCase(name),
      species: species,
      photo: options.withImage ? await getVetecardPhotoUrl(id) : undefined,
      birthDate: new Date(birth.date),
    }))
  )

  const requests = options.withRequests ? await getVetecardRequests(user) : []

  // TODO: Handle filters on backend side.
  const filtered = [...vetecards, ...requests].filter(item =>
    params.species ? item.species === params.species : true
  )

  return {
    vetecards: filtered,
    hasMore: (params.limit && filtered.length > params.limit) || false,
  }
}

export const getVetecardFull = async (vetecardId: VetecardBasic['id']): Promise<VetecardFull> => {
  const response = await api.get(`/0/vetecard/${vetecardId}`)
  const { id, animal, passport, createdAt, modifiedAt, email } =
    response.data as VetecardWithDetailsT
  const identifier = response.data.identifier as VetecardFull['identifier'] // Workaround for unneccessary dynamic typing.
  return {
    id,
    email,
    name: animal.name,
    species: animal.species as VetecardFull['species'],
    birthDate: new Date(animal.birth.date),
    photo: await getVetecardPhotoUrl(id),
    breed: animal.speciesBreed || undefined,
    sex: animal.sex || undefined,
    coatingColour: animal.coatingColour || undefined,
    identifier: {
      ...identifier,
      date: identifier?.date ? new Date(identifier.date) : undefined,
    },
    createdAt: new Date(createdAt),
    modifiedAt: modifiedAt ? new Date(modifiedAt) : undefined,
    ...(passport.number && passport.deliveryDate && passport.expirationDate
      ? {
          passport: {
            number: passport.number,
            deliveryDate: new Date(passport.deliveryDate),
            expirationDate: new Date(passport.expirationDate),
          },
        }
      : {}),
  }
}

export const getMainVeterinarians = async (
  vetecardId: VetecardBasic['id']
): Promise<VeterinarianBasic[]> => {
  const response = await api.get(`/0/vetecard/${vetecardId}/veterinarians/associate`)
  const data = response.data as ListVetecardVeterinariansResponseT
  return data.map(({ veterinarian: { id, firstName, lastName, institutions } }) => ({
    veterinarianId: id,
    firstName: toTitleCase(firstName),
    lastName: toTitleCase(lastName),
    institutions: institutions.map(({ name, city }) => ({
      name: toTitleCase(name),
      contact: { city: toTitleCase(city) },
    })),
  }))
}

export const assignVeterinarian = async (
  { userType }: UserBasic,
  veterinarianId: VeterinarianBasic['veterinarianId'],
  vetecardId: VetecardBasic['id']
): Promise<VeterinarianBasic> => {
  await api.post(`/0/vetecard/${vetecardId}/veterinarian/${veterinarianId}`, {
    type: 'associate',
    duration: {
      type: 'forever',
    },
    confirmedByOwner: isOwnerType(userType),
    confirmedByVeterinarian: userType === 'veterinarian',
  })
  const response = await api.get(`/0/veterinarian/preview/${veterinarianId}`)
  const { firstName, lastName, institutions } = response.data as EmployedVeterinarian
  return {
    veterinarianId,
    firstName,
    lastName,
    institutions: institutions.map(({ name, city }) => ({
      name,
      contact: { city: city === null ? undefined : city },
    })),
  }
}

export const archiveMainVeterinarian = async (
  veterinarianId: VeterinarianBasic['veterinarianId'],
  vetecardId: VetecardBasic['id']
) => {
  await api.delete(`/0/vetecard/${vetecardId}/veterinarian/${veterinarianId}`)
  return veterinarianId
}

export const getSharedVeterinarians = async (
  user: UserBasic,
  vetecardId: VetecardBasic['id']
): Promise<UserShared[]> => {
  const response = await api.get(`/0/vetecard/${vetecardId}/veterinarians/temporary`)
  const data = response.data as ListVetecardVeterinariansResponseT
  return data.map(({ veterinarian: { id, firstName, lastName }, duration }) => {
    return {
      veterinarianId: id,
      keycloakId: id !== null ? id.toString() : '', //FIXME: why anyone would need profile id for other veterinarian?
      userType: 'veterinarian',
      firstName,
      lastName,
      timeRangeType: duration.type,
      dateFrom:
        duration.type === 'date_range' || duration.type === 'oneyear'
          ? new Date(duration.from)
          : undefined,
      dateTo:
        duration.type === 'date_range'
          ? new Date(duration.to)
          : duration.type === 'oneyear'
          ? pipe(
              new Date(duration.from),
              from => [from, new Date(from.valueOf())],
              ([from, to]) => {
                to.setFullYear(from.getFullYear() + 1)
                return to
              }
            )
          : undefined,
    }
  })
}

export const getSharedThirdParty = async (
  user: UserBasic,
  vetecardId: VetecardBasic['id']
): Promise<UserShared[]> => {
  const response = await api.get(`/0/vetecard/${vetecardId}/petsitters`)
  const data = response.data as ListVetecardPetsittersResponseT
  return data.map(({ petsitter: { id, firstName, lastName }, duration }) => ({
    keycloakId: id,
    userType: 'petsitter',
    firstName,
    lastName,
    timeRangeType: duration.type,
    dateFrom:
      duration.type === 'date_range' || duration.type === 'oneyear' ? duration.from : undefined,
    dateTo:
      duration.type === 'date_range'
        ? duration.to
        : duration.type === 'oneyear'
        ? pipe(
            new Date(duration.from),
            from => [from, new Date(from.valueOf())],
            ([from, to]) => {
              to.setFullYear(from.getFullYear() + 1)
              return to
            }
          )
        : undefined,
  }))
}

export const editDescription = async (
  vetecardId: VetecardBasic['id'],
  payload: EditDescriptionPayload
): Promise<Partial<VetecardFull>> => {
  await api.put(`/0/vetecard/${vetecardId}/details/description`, {
    speciesBreed: payload.breedCode,
    sex: payload.sex as VetecardAnimalSexT,
    birthDate: new Date(payload.birthDate),
    coatingColour: payload.coatingColour,
  })
  return {
    breed: payload.breedCode,
    sex: payload.sex as VetecardFull['sex'],
    birthDate: new Date(payload.birthDate),
    coatingColour: payload.coatingColour,
  }
}

export const editMarking = async (
  vetecardId: VetecardBasic['id'],
  payload: EditMarkingPayload
): Promise<VetecardFull['identifier']> => {
  await api.put(`/0/vetecard/${vetecardId}/identifier`, payload)
  return {
    ...payload,
    date: payload.date ? new Date(payload.date) : undefined,
  }
}

export const editPassport = async (
  vetecardId: VetecardBasic['id'],
  payload: EditPassportPayload
) => {
  await api.put(`/0/vetecard/${vetecardId}/details/passport`, {
    number: payload.number,
    deliveryDate: new Date(payload.deliveryDate),
    expirationDate: new Date(payload.expirationDate),
  })
  return {
    number: payload.number,
    deliveryDate: new Date(payload.deliveryDate),
    expirationDate: new Date(payload.expirationDate),
  }
}

export const uploadPhoto = async (vetecardId: VetecardBasic['id'], file: FileInput) => {
  const storage = await getMinioClient()
  const croppedImage = await getCroppedImg(file.data, {
    pixelCrop: file.croppedAreaPixels,
    quality: 60,
    size: {
      width: 392,
      height: 256,
    },
  })
  if (croppedImage) {
    const decoded = dataUriToBuffer(croppedImage)
    await putObject(storage, vetecardId, photoBucketName, '', Buffer.from(decoded.buffer))
    return await getPresignedUrl(storage, vetecardId, photoBucketName)
  } else {
    throw new Error('Image could not be cropped')
  }
}

export const transferOwnership = async (
  { keycloakId, userType }: UserBasic,
  vetecardId: VetecardBasic['id'],
  isRequest: boolean,
  email: string,
  documentDate?: string
): Promise<VetecardBasic['id']> => {
  switch (userType) {
    case 'breeder': {
      const response = await api.get(`/0/individual/search/${email}`)
      const individuals = response.data as V0GetIndividualSearchResponseT
      const matchingIndividual = individuals.find(individual => individual.email === email)
      if (typeof matchingIndividual === 'undefined') {
        throw new Error(
          `Transferring Ownership to not registered individual e-mail ${JSON.stringify(
            email
          )} is not implemented.`
        )
      } else {
        await api.post(
          `/0/breeder/${keycloakId}/${
            isRequest ? 'vetecard_request' : 'vetecard'
          }/${vetecardId}/owner/individual/${matchingIndividual.id}`,
          documentDate && { consent: { documentDate } }
        )
      }
      break
    }
    default:
      throw new Error('Unhandled userType')
  }

  return vetecardId
}
