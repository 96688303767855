import React from 'react'
import { useTranslation } from 'react-i18next'
import EditFileContainer from '../containers/EditFileContainer'
import UploadFileContainer from '../containers/UploadFileContainer'
import {
  type DeleteFilePayload,
  type FileCategory,
  type FileEntity,
  type VetecardBasic,
} from '../typings'
import formatDate from '../utils/formatDate'
import AddButton from './AddButton'
import Avatars from './Avatars'
// import { Stream } from 'stream'
import DropdownButton from './DropdownButton'
import Modal, { type ModalRef } from './Modal'
import Table from './Table'

interface FilesProps {
  vetecardId: VetecardBasic['id']
  data: FileEntity[]
  category: FileCategory
  onDownload: (name: string) => void
  onDelete?: (payload: DeleteFilePayload) => void
}

const Files = ({ vetecardId, data, category, onDownload, onDelete }: FilesProps) => {
  const { t } = useTranslation()
  const modalRef = React.useRef<ModalRef>(null)
  const [sortBy, setSortBy] = React.useState('treatmentDate')
  const [initialValues, setInitialValues] = React.useState<FileEntity | undefined>()

  React.useEffect(() => {
    if (initialValues) modalRef.current?.open()
  }, [initialValues])

  const formattedData = React.useMemo(
    () =>
      data
        .sort((a, b) => {
          switch (sortBy) {
            case 'type':
              if (a.type > b.type) return 1
              if (a.type < b.type) return -1
              return 0
            case 'name':
              if (a.name > b.name) return 1
              if (a.name < b.name) return -1
              return 0
            case 'treatmentDate':
              if (!a.treatmentDate || !b.treatmentDate) return -1
              if (a.treatmentDate > b.treatmentDate) return 1
              if (a.treatmentDate < b.treatmentDate) return -1
              return 0
            case 'documentDate':
              if (!a.documentDate || !b.documentDate) return -1
              if (a.documentDate > b.documentDate) return 1
              if (a.documentDate < b.documentDate) return -1
              return 0
            default:
              return 0
          }
        })
        .map(file => {
          const {
            id,
            name,
            url,
            type,
            access,
            treatmentDate,
            documentDate,
            sharedWith,
            canEdit,
            canDelete,
          } = file
          console.log('sharedWith: ', sharedWith)
          return {
            // name: <DownloadLink onClick={() => onDownload(name)}>{name}</DownloadLink>,
            name: name,
            type: t(`fileType.${type}`),
            treatmentDate: formatDate(treatmentDate),
            documentDate: formatDate(documentDate),
            sharedWith: sharedWith?.length ? (
              <Avatars data={sharedWith} />
            ) : (
              <em>{access ? t(`accessLevel.${access}`) : '-'}</em>
            ),
            actions: (
              <DropdownButton
                size="sm"
                href={url}
                actions={[
                  ...(canEdit
                    ? [
                        {
                          label: t('edit'),
                          onClick: () => setInitialValues(file),
                        },
                      ]
                    : []),
                  ...(canDelete && onDelete
                    ? [
                        {
                          label: t('delete'),
                          onClick: () => onDelete({ id, name, vetecardId, category }),
                        },
                      ]
                    : []),
                ]}
              >
                {t('download')}
              </DropdownButton>
            ),
          }
        }),
    [data, sortBy]
  )

  return (
    <>
      <Table
        labels={[
          { name: t('type'), field: 'type', sortable: true },
          { name: t('name'), field: 'name', sortable: true },
          { name: t('date'), field: 'treatmentDate', sortable: true },
          { name: t('documentDate'), field: 'documentDate', sortable: true },
          { name: t('sharedWith'), field: 'sharedWith' },
          { field: 'actions' },
        ]}
        data={formattedData}
        emptyText={t('noResults.noFiles')}
        sortBy={sortBy}
        onSort={field => setSortBy(field)}
        toRight
      />
      <AddButton padded onClick={() => modalRef.current?.open()}>
        {t('uploadFile')}
      </AddButton>
      <Modal
        size="lg"
        title={initialValues ? t('editFile') : t('uploadFile')}
        ref={modalRef}
        onClose={() => setInitialValues(undefined)}
      >
        {initialValues ? (
          <EditFileContainer
            vetecardId={vetecardId}
            category={category}
            initialValues={initialValues}
            onSuccess={() => modalRef.current?.close()}
          />
        ) : (
          <UploadFileContainer
            vetecardId={vetecardId}
            category={category}
            onSuccess={() => modalRef.current?.close()}
          />
        )}
      </Modal>
    </>
  )
}

export default Files
